
import html2pdf from 'html2pdf.js';
import React, { useState, useEffect } from 'react';


function Calculator() {
    const [activeSection, setActiveSection] = useState('primera');
    const [visibleBanner, setVisibleBanner] = useState('hidden')
    const [visibleSpinner, setVisibleSpinner] = useState('hidden')
    const [selectedOptions, setSelectedOptions] = useState({
        features: [
            { title: 'Web (hasta 6 páginas)', price: 750 }
        ]
    });
    const [cuenta, setCuenta] = useState(0);

    const [email, setEmail] = useState('');
    const [pdfData, setPdfData] = useState('')


    useEffect(() => {
        // Calculate the total based on selected options
        let total = 0;
        for (const key in selectedOptions) {
            const option = selectedOptions[key];
            if (Array.isArray(option)) {
                total += option.reduce((acc, item) => acc + item.price, 0);
            } else {
                total += option.price;
            }
        }
        setCuenta(total);
    }, [selectedOptions]);

    function handleSelection(section, option) {
        if (section === 'features') {
            setSelectedOptions(prev => {
                const currentFeatures = prev.features || [];
                if (currentFeatures.find(feature => feature.title === option.title)) {
                    // Remove feature if it's already selected
                    return {
                        ...prev,
                        features: currentFeatures.filter(feature => feature.title !== option.title)
                    };
                } else {
                    // Add feature if it's not selected yet
                    return {
                        ...prev,
                        features: [...currentFeatures, option]
                    };
                }
            });
        } else {
            setSelectedOptions(prev => ({ ...prev, [section]: option }));
        }
    }

    function goToNextSection() {
        if (activeSection === 'primera') setActiveSection('segunda');
        if (activeSection === 'segunda') setActiveSection('tercera');
        if (activeSection === 'tercera') setActiveSection('cuarta');
        if (activeSection === 'cuarta') setActiveSection('quinta');
    }
    function goToPreviousSection() {
        if (activeSection === 'segunda') setActiveSection('primera');
        if (activeSection === 'tercera') setActiveSection('segunda');
        if (activeSection === 'cuarta') setActiveSection('tercera');
        if (activeSection === 'quinta') setActiveSection('cuarta');
    }

    function flattenSelectedOptions(options) {
        return Object.values(options).flat();
    }

    if(selectedOptions) {
        console.log(flattenSelectedOptions(selectedOptions))
    }


    async function sendTextDataEmail(email, selectedOptions,cuenta) {
        setVisibleSpinner('block')
        // Prepare the request data based on the textual data you want to send
        const requestData = {
            email: email,
            selectedOptions: flattenSelectedOptions(selectedOptions), 
            total: cuenta, 
        };
        try {
            console.log(requestData);
            const response = await fetch('https://email-pymes-presu-7ce37347a2b5.herokuapp.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // Handle success - maybe show a success message or something
                console.log("Email sent successfully:", data);
                setVisibleSpinner('hidden')
                setVisibleBanner('block')
            } else {
                // Handle any errors that are returned from your server
                console.error("Error sending email:", data);
            }
    
        } catch (error) {
            // Handle fetch errors
            console.error("Network error:", error);
        }
    }


    


    function downloadReceiptAsPDF() {
        const receiptElement = document.getElementById('websiteReceipt');
        const opt = {
            margin: 10,
            filename: 'erimi-presupuesto-web.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        html2pdf().from(receiptElement).set(opt).outputPdf().then((pdf) => {
            // Convert the pdf into base64 to store it into a state
            const base64 = btoa(new Uint8Array(pdf).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            setPdfData(base64);
        });
    }
    

    if(activeSection === 'primera') {
        return (
            <div className="p-10 bg-gray-100 rounded h-[100%] my-24">
                <div className='title'>
                <div className='flex flex-wrap justify-center items-center mt-[10%]'>
                    <p className='font-bold text-4xl px-2 md:px-24 text-center'>Calcula tu precio y configura tu web</p>
                </div>
                <div className='mt-12 flex flex-wrap justify-center items-center'>
                <button className="mt-4 p-2 bg-[#882CFE] px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white font-semibold text-xl" onClick={goToNextSection}>Empezar</button>
                </div>
                </div>
            </div>
        );
    }
    if (activeSection === 'segunda') {
        const featureOptions = [
            { title: 'Sistema de reserva', price: 300 },
            { title: 'Blog', price: 200},
            { title: 'Tienda Online', price: 400 },
            { title: 'Chat', price: 100}
        ];

        return (
            <div className="p-10 bg-gray-100">
                <div className="my-6">
                    
                    <p className='text-center my-12 font-semibold text-xl text-[#882CFE]'>Selecciona las características y funcionalidades de tu web</p>
                    <div className='flex flex-wrap justify-center items-center'>
                   
                    <button className="font-semibold md:w-[200px] md:h-[120px] w-[70vw] rounded h-[100px] mx-4 my-2 p-2 bg-white border-[#882CFE] border-2 cursor-not-allowed" disabled>
                        <p>Web (hasta 6 páginas)</p>
                    </button>

                        {featureOptions.map(option => (
                            <button
                                key={option.title}
                                className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.features && selectedOptions.features.find(feature => feature.title === option.title) ? 'border-[#882CFE]' : 'border-gray-100'} border-2`}
                                onClick={() => handleSelection('features', option)}
                            >
                            <div>
                                <p>{option.title}</p>
                            </div>
                            </button>
                        ))}
                    </div>

                </div>
                <div className='text-center'>
                    <button className="mt-4 p-2 bg-[#882CFE] px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white" onClick={goToNextSection}>Siguiente</button>
                </div>

            </div>
        );
    }

    if (activeSection === 'tercera') {
        return (
            <div className="p-10 bg-gray-100">
                <div className="my-6">
                
                <p className='text-center my-12 font-semibold text-xl text-[#882CFE]'>¿Tienes ya tu dominio y plan de hosting?</p>
                    <div>
                    <p className='text-center mb-4 font-semibold text-lg'>Dominio</p>
                    <div className="flex flex-wrap justify-center items-center">
                        <button className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.dominio && selectedOptions.dominio.title === 'Tengo dominio' ? 'border-[#882CFE]' : 'border-gray-100'} border-2`} onClick={() => handleSelection('dominio', { title: 'Tengo dominio', price: 0 })}>Tengo dominio</button>
                        <button className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.dominio && selectedOptions.dominio.title === 'Dominio' ? 'border-[#882CFE]' : 'border-gray-100'} border-2`} onClick={() => handleSelection('dominio', { title: 'Dominio', price: 50 })}>No tengo dominio</button>
                    </div>
                    <p className='text-center mt-12 font-semibold text-lg'>Hosting</p>
                    <div className="flex flex-wrap justify-center items-center mt-4">
                        <button className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.hosting && selectedOptions.hosting.title === 'Tengo hosting' ? 'border-[#882CFE]' : 'border-gray-100'} border-2`} onClick={() => handleSelection('hosting', { title: 'Tengo hosting', price: 0 })}>Tengo hosting</button>
                        <button className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.hosting && selectedOptions.hosting.title === 'Hosting' ? 'border-[#882CFE]' : 'border-gray-100'} border-2`} onClick={() => handleSelection('hosting', { title: 'Hosting', price: 80 })}>No tengo hosting</button>
                    </div>
                    </div>
                   <div className='mt-12 flex flex-wrap justify-center items-center'>
                   <button className="mt-4 p-2 bg-[#882CFE] px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white mr-4" onClick={goToNextSection}>Siguiente</button>
                    <button className="mt-4 p-2 bg-gray-500 px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white" onClick={goToPreviousSection}>Atrás</button>
                   </div>
                </div>
            </div>
        );
    }

    if (activeSection === 'cuarta') {
        const finalesOptions = [
            { title: 'SEO', price: 150 },
            { title: 'Google Maps', price: 50 },
            { title: 'Analytics', price: 150 },
            { title: 'Mantenimiento y Cambios de Contenido (Pago anual)', price: 300 }
        ];

        return (
            <div className="p-10 bg-gray-100">
                <div className="my-6">
                
                <p className='text-center my-12 font-semibold text-xl text-[#882CFE]'>Toques finales</p>
                   <div className='flex flex-wrap justify-center items-center'>
                   {finalesOptions.map(option => (
                        <button
                            key={option.title}
                            className={`rounded font-semibold md:w-[200px] md:h-[120px] w-[70vw] h-[100px] mx-4 my-2 p-2 bg-white ${selectedOptions.features && selectedOptions.features.find(feature => feature.title === option.title) ? 'border-[#882CFE]' : 'border-gray-100'} border-2`}
                            onClick={() => handleSelection('features', option)}
                        >
                            {option.title}
                        </button>
                    ))}
                   </div>
                </div>
                <div className='mt-12 flex flex-wrap justify-center items-center'>
                   <button className="mt-4 p-2 bg-[#882CFE] px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white mr-4" onClick={goToNextSection}>Siguiente</button>
                    <button className="mt-4 p-2 bg-gray-500 px-12 py-4 px-12 py-4 px-12 py-4 rounded text-white" onClick={goToPreviousSection}>Atrás</button>
                   </div>
            </div>
        );
    }

    if (activeSection === 'quinta') {
        return (
            <div className="p-4 plain bg-gray-100">
                <div className="my-6">
                    <div id="websiteReceipt" className="bg-white p-4 md:w-[50%] md:ml-[25%] mb-24">
                        <h2 className="text-2xl mb-4">ERIMI - Presupuesto Online</h2>
                        {Object.values(selectedOptions).flat().map(option => (
                            <div className="flex justify-between border-b py-2" key={option.title}>
                                <span>{option.title}</span>
                                <span>{option.price}€</span>
                            </div>
                        ))}
                        <div className="flex justify-between mt-12">
                            <strong>Total</strong>
                            <strong>{cuenta}€</strong>
                        </div>
                    </div>
                   
                </div>
                <div className="mt-4 ml-[16%]">
                   <div className='w-[90%] md:w-[80%]'>
                   <div className='text-center w-[100%]'>
                        <div role="status" className={`${visibleSpinner} w-[30%] md:ml-[50%] ml-[47%] -mt-[50px] mb-[40px]`}>
                            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                        </div>
                        <p className={`${visibleBanner} text-center mb-6 `}><span className='w-fit bg-green-400 px-6 py-4 rounded'>Hemos recibido tu presupuesto, gracias.</span></p>
                   </div>
                    <p className='text-center mb-6 text-lg'>Envíanos tu presupuesto y nos pondremos en contacto contigo lo antes posible.</p>
                   <div className='w-[100%] ml-[0%] md:w-[50%] md:ml-[25%]'>
                   <input 
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Tu email de contacto"
                        className="p-2 border rounded mb-4 w-[70%] h-12 rounded-l-xl"
                    />
                    <button className='h-12 w-[30%] bg-[#882CFE] rounded-r-xl font-semibold title text-white' onClick={()=> sendTextDataEmail(email, selectedOptions, cuenta) }>Enviar</button>
                   </div>
                   </div>
                   <div className='mt-4 flex flex-wrap justify-center items-center'>
                   <button className="bg-gray-500  px-12 py-4 rounded text-white mr-[15%]" onClick={goToPreviousSection}>Atrás</button>
                </div>
                </div>
            </div>
        );
    }
    


}


export default Calculator;
