import './App.css';
import React, { useState, useRef } from 'react';
import Calculator from './comp/Calculator'
import { PopupButton } from '@typeform/embed-react' 

import shop from './img/shop.png'
import lin from './img/linw.png'
import ins from './img/insw.png'
import reset from './img/reset.png'
import inge from './img/inge.png'


function App() {

  const [modalContact, setModalContact] = useState('hidden')


  return (
    <div className="">
    <div class="mx-auto max-w-7xl pt-16 sm:pt-24">
        <div class="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div class="space-y-8">
                    <div class="space-y-4">
                        <div class="space-y-2">
                            <span
                                class="rounded-full uppercase bg-pink-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                                ERIMI PYMES
                            </span>
                            <p class="title text-6xl font-bold tracking-tight  sm:text-5xl md:text-5xl">
                                <span class="sm:text-6xl">
                                </span> Mejora la presencia 
                                <span
                                    class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-pink-600 mr-4"> online
                                </span>
                                de tu negocio
                            </p>
                        </div>

                        <p class="plain text-base text-black sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                           Consigue que internet sea tu mejor aliado. 
                           <div className='flex mt-12'>
                              <img className='w-6 h-6 mr-2 mt-[3px]' src="https://img.icons8.com/ios/50/domain--v1.png" />
                              <p className='text-black'>Paginas Web</p>
                           </div>
                           <div className='flex mt-4'>
                              <img className='w-6 h-6 mr-2 mt-[3px]' src="https://img.icons8.com/ios-filled/50/shopping-cart.png" />
                              <p className='text-black'>E-commerce</p>
                           </div>
                           <div className='flex mt-4'>
                              <img className='w-6 h-6 mr-2 mt-[3px]' src="https://img.icons8.com/pastel-glyph/64/purchase-order.png" />
                              <p className='text-black'>Sistema de pedidos</p>
                           </div>
                           <div className='flex mt-4'>
                              <img className='w-6 h-6 mr-2 mt-[3px]' src="https://img.icons8.com/pastel-glyph/64/appointment-time.png" />
                              <p className='text-black'>Sistema de reservas</p>
                           </div>
                           <div className='flex mt-4'>
                              <img className='w-6 h-6 mr-2 mt-[3px]' src="https://img.icons8.com/ios-filled/50/laptop-metrics.png" />
                              <p className='text-black'>SEO</p>
                           </div>
                        </p>

                    </div>

                    <div class="border-t border-gray-700"></div>



                    <div class="flex space-x-4 items-center text-white">
                        <div><a href="https://topitcompanies.co/categories/ecommerce-developers/shopify/country/spain" target="_blank"> <img className='h-[100px] w-[100px]' src="https://topitcompanies.co/badges/shopify.png" alt="shopify development companies" title="Shopify Development Companies" /></a></div>
                        <div><a href="https://topitcompanies.co/categories/ecommerce-developers/country/spain" target="_blank"> <img className='h-[100px] w-[100px]' src="https://topitcompanies.co/badges/ecommerce-developers.png" alt="ecommerce app developers" title="Ecommerce App Developers" /></a></div>   
                    </div>
                </div>
            </div>

            <div class="flex items-center w-full col-span-6">
                <div class="px-6 h-96 lg:h-100% w-full max-w-2xl col-span-6 flex items-center mx-auto mt-24 ml-[8vw]">
                    <div style={{width: '100%',  height: '100%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <img src={shop} />
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div>
                      <Calculator />
                    </div>
                    
      <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-32">
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div class="max-w-lg">
            <p className='text-[#882CFE] font-bold text-4xl title'>SOBRE NOSOTROS</p>
                <p class="mt-4 text-gray-600 text-lg">ERIMI PYMES nace para facilitar el acceso al mundo digital y mejorar la presencia en internet de las pequeñas empresas y autónomos en España..</p>
                <div class="mt-8 flex">
                    <a>
                      <img className='bg-[#882CFE] mx-2 w-12 p-2 rounded rounded-full' src={ins} />
                    </a>
                    <a>
                      <img className='bg-[#882CFE] mx-2 w-12 p-2 rounded rounded-full' src={lin} />
                    </a>
                </div>
            </div>
            <div class="mt-12 md:mt-0">
            <iframe className='rounded rounded-2xl w-[100%] h-[350px]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25767.759198126365!2d-5.391616837494726!3d36.1672870833729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0cc0f52a2e26f3%3A0x50a332203e0d0690!2s11300%20La%20L%C3%ADnea%20de%20la%20Concepci%C3%B3n%2C%20C%C3%A1diz!5e0!3m2!1sen!2ses!4v1693134413982!5m2!1sen!2ses"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>


      <div className='md:px-24 px-6 my-24'>
      <p className='my-6 text-[#882CFE] font-bold text-5xl title text-center mb-12'>CLIENTES</p>
      <p className='mb-12 text-center md:px-24 plain text-xl'>Estos son algunos de los clientes con los que hemos trabajado recientemente</p>
        <div className='md:grid md:grid-cols-2 place-items-center'>
          <div className='h-fit md:w-fit rounded rounded-xl border border-4 border-[#882CFE]'>
            <img className='h-[400px] mx-[30px] p-4' src={inge} />
            <div className='bg-[#882CFE] rounded px-4 py-6 '>
            <p className='font-semibold text-3xl text-white'>Ingeasist</p>
            <p className='text-gray-200'>Web Informativa personalizada</p>
           </div>
          </div>
          <div className='h-fit md:w-fit md:my-0 my-12 rounded rounded-xl border border-4 border-[#882CFE]'>
            <img className='h-[400px] mx-[30px] p-4' src={reset} />
           <div className='bg-[#882CFE] rounded px-4 py-6 '>
            <p className='font-semibold text-3xl text-white'>R3SET</p>
            <p className='text-gray-200'>E-commerce Shopify con integraciones</p>
           </div>
          </div>
        </div>
      </div>
      <div className='md:px-24 px-6 py-12 text-center'>
        <p className='font-semibold text-5xl text-[#882CFE] '>¿Hablamos?</p>
        <p className='md:px-24 mt-6 text-lg text-gray-700'>Comparte con nosotros los detalles de tu próximo proyecto y déjalo en nuestras manos. Nuestro equipo te contactará pronto para mostrarte cómo podemos ayudarte.</p>
        <button className='mt-6 text-white font-semibold text-xl rounded rounded-xl bg-[#882CFE] px-12 py-4' onClick={() => setModalContact('block')}>Contacto</button>
      </div>
<div className={`h-[100vh] w-[100vw] fixed top-0 ${modalContact}`}>
    <div className='h-full w-full'>
    <button className='absolute top-4 right-4 font-semibold text-2xl' onClick={() => setModalContact('hidden')}>X</button>
<iframe className='w-full h-full' src="https://us21.list-manage.com/contact-form?u=3a361aab6d676de4d61014fbe&form_id=bd7a69a906687751841a3868dafef629" />
    </div>
</div>
      <div className='my-12'>
        <hr />
        <div className='grid grid-cols-2'>
          <div>
          <img className='h-12 mt-8 ml-4' src={"https://www.erimi.agency/static/media/logo.1a484704e4aa7c9734a3.png"} />
          </div>
          <div className='absolute right-4 mt-12 flex'>
            <a>
            <div className='bg-[#882CFE] p-2 rounded-full'>
            <img className='h-8' src={ins} />
            </div>
            </a>
            <a>
            <div className='bg-[#882CFE] mx-4 p-2 rounded-full'>
            <img className='h-8' src={lin} />
            </div>
            </a>
            
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
